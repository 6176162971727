export const states = [
    ["alabama", "Alabama"],
    ["alaska", "Alaska"],
    ["arizona", "Arizona"],
    ["arkansas", "Arkansas"],
    ["california", "California"],
    ["colorado", "Colorado"],
    ["connecticut", "Connecticut"],
    ["delaware", "Delaware"],
    ["florida", "Florida"],
    ["georgia", "Georgia"],
    ["hawaii", "Hawaii"],
    ["idaho", "Idaho"],
    ["illinois", "Illinois"],
    ["indiana", "Indiana"],
    ["iowa", "Iowa"],
    ["kansas", "Kansas"],
    ["kentucky", "Kentucky"],
    ["louisiana", "Louisiana"],
    ["maine", "Maine"],
    ["maryland", "Maryland"],
    ["massachusetts", "Massachusetts"],
    ["michigan", "Michigan"],
    ["minnesota", "Minnesota"],
    ["mississippi", "Mississippi"],
    ["missouri", "Missouri"],
    ["montana", "Montana"],
    ["nebraska", "Nebraska"],
    ["nevada", "Nevada"],
    ["new-hampshire", "New Hampshire"],
    ["new-jersey", "New Jersey"],
    ["new-mexico", "New Mexico"],
    ["new-york", "New York"],
    ["north-carolina", "North Carolina"],
    ["north-dakota", "North Dakota"],
    ["ohio", "Ohio"],
    ["oklahoma", "Oklahoma"],
    ["oregon", "Oregon"],
    ["pennsylvania", "Pennsylvania"],
    // ["puerto-rico", "Puerto Rico"],
    ["rhode-island", "Rhode Island"],
    ["south-carolina", "South Carolina"],
    ["south-dakota", "South Dakota"],
    ["tennessee", "Tennessee"],
    ["texas", "Texas"],
    ["utah", "Utah"],
    ["vermont", "Vermont"],
    ["virginia", "Virginia"],
    ["washington", "Washington"],
    ["washington-dc", "Washington D.C."],
    ["west-virginia", "West Virginia"],
    ["wisconsin", "Wisconsin"],
    ["wyoming", "Wyoming"],
    // ["armed-forces-aa", "Armed Forces - AA"],
    // ["armed-forces-ae", "Armed Forces - AE"],
    // ["armed-forces-ap", "Armed Forces - AP"],
    ["alberta", "Alberta"],
    ["british-columbia", "British Columbia"],
    ["manitoba", "Manitoba"],
    ["new-brunswick", "New Brunswick"],
    // ["newfoundland", "Newfoundland"],
    // ["northwest-territories", "Northwest Territories"],
    // ["nova-scotia", "Nova Scotia"],
    // ["nunavut", "Nunavut"],
    ["ontario", "Ontario"],
    // ["prince-edward-island", "Prince Edward Island"],
    // ["quebec", "Quebec"],
    // ["saskatchewan", "Saskatchewan"],
    // ["yukon", "Yukon"],
]

export const stateObjects = states.map((state) => ({
    label: state[1],
    value: state[0],
}))

export const stateObjects2 = states.map((state) => ({
    text: state[1],
    value: state[0],
}))

export const statesObject = Object.fromEntries(states)
